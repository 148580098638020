import React from "react";
import { Link } from "gatsby"
import "../components/Footer.scss";
import Logo from "../images/logo-2.svg";
import IconLinkedin from "../images/icon-linkedin.svg";


function Footer(){
  return(
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src={Logo} width="110" className="mb-4" alt="Logo" />
            <p>
              Virtual Ed Global
              <br/><i>2021.</i>
            </p>
          </div>
          <div className="col-md-4">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/servicios">Servicios</Link></li>
              <li><Link to="/productos">Productos</Link></li>
              <li><Link to="/soluciones">Soluciones</Link></li>
              <li><Link to="/recursos">Recursos</Link></li>
              <li><Link to="/about">About</Link></li>
            </ul>
          </div>
          <div className="col-md-4">
            <a href="https://www.linkedin.com/company/virtual-ed-global-edtech/" target="_blank" rel="noreferrer">
              <img src={IconLinkedin} alt="" className="mb-3" />
            </a>
            <p>
              Dirección: CRA San Jerónimo, 15; 
              <br/>Planta 3, Puerta 319; Madrid
              <br/><a href="mailto:info@virtualedglobal.com">info@virtualedglobal.com</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer