import React from "react";
import { Link } from "gatsby"
import Logo from "../images/logo.svg";
import "../components/Navbar.scss";
import IconLang from "../images/language_icon.svg"

function Navbar () {

  return(
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/"><img src={Logo} width="170" alt="Linkedin" /></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/servicios">Servicios</Link>
            </li>
            <li className="nav-item dropdown_">
              <Link className="nav-link dropdown-toggle_" to="/productos/" id="navbarDropdown_" role="button" data-toggle="dropdown_" aria-expanded="false">
                Productos
              </Link>
              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/productos#lms/">LMS</a>
                <a className="dropdown-item" href="/productos#crm/">CRM</a>
                <a className="dropdown-item" href="/productos#crm/">Student help desk</a>
                <a className="dropdown-item" href="/productos#crm/">AI Predictive Revenue</a>
                <a className="dropdown-item" href="/productos#crm/">CMS</a>
                <a className="dropdown-item" href="/productos#crm/">Proctoring</a>
                <a className="dropdown-item" href="/productos#crm/">Planificación académica</a>
                <a className="dropdown-item" href="/productos#crm/">Student track record</a>
                <a className="dropdown-item" href="/productos#crm/">Gestión de la oferta académica</a>
              </div> */}
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/soluciones/">Soluciones</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about/">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/recursos/">Recursos</Link>
            </li>
            <li className="nav-item visible-md">
              <a className="nav-link" href="/#contacto">Conversemos</a>
            </li>
          </ul>
          <a className="btn btn-primary my-2 my-lg-0 hidden-md" href="/#contacto">
            Conversemos
          </a>
          <Link to="/en/" className="icon-lang">
            <img src={IconLang} width="28" alt="lang-icon" />
            EN
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar
