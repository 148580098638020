import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import "../scss/main.scss";

import Navbar from "../components/Navbar";
import CardBox from "../components/CardBox";
import HeroHome from "../components/HeroHome";
import SideTabs from "../components/SideTabs";
import LogosGallery from "../components/LogosGallery";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import WhatsappButton from "../components/WhatsappButton";

import { slugify, limitString } from "../utils/helpers";

import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => {
  /* const domain = ""; */

  const data = useStaticQuery(graphql`
    query {
      allContentfulService(sort: { fields: title_es, order: ASC }) {
        nodes {
          title_es
        }
      }
      allContentfulProduct(sort: { fields: title_es, order: ASC }) {
        nodes {
          title_es
        }
      }
      allContentfulSolution(sort: { fields: title_es, order: ASC }) {
        nodes {
          title_es
        }
      }
      allContentfulHomePageTemplate {
        nodes {
          hero_title_es
          childContentfulHomePageTemplateHeroDescriptionEsTextNode {
            hero_description_es
          }
          hero_image {
            file {
              url
            }
          }

          childContentfulHomePageTemplateSectionServiciosDescriptionEsTextNode {
            section_servicios_description_es
          }
          childContentfulHomePageTemplateSectionProductsDescriptionEsTextNode {
            section_products_description_es
          }
          childContentfulHomePageTemplateSectionSolutionsDescriptionEsTextNode {
            section_solutions_description_es
          }
        }
      }
    }
  `);
  
  const heroTitle = data.allContentfulHomePageTemplate.nodes[0].hero_title_es;
  const heroDescription =
  data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateHeroDescriptionEsTextNode
  .hero_description_es;
  const heroImage = data.allContentfulHomePageTemplate.nodes[0].hero_image.file.url;
  const pageTitle = "Virtual Ed Global | " + heroTitle;

  const servicesDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateSectionServiciosDescriptionEsTextNode
      .section_servicios_description_es;
  const productsDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateSectionProductsDescriptionEsTextNode
      .section_products_description_es;
  const solutionsDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateSectionSolutionsDescriptionEsTextNode
      .section_solutions_description_es;

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta name="description" content={heroDescription} />
        {/* <link rel="icon" type="image/png" href="" /> */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImage} />
        <meta name="twitter:image" content={heroImage}/>
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} />        
      </Helmet>

      <Navbar />
      <HeroHome
        title={heroTitle}
        description={heroDescription}
        image={heroImage}
        buttonLabel="Conversemos"
        link="contacto"
      />

      <div className="page-wrapper">
        <section>
          <div className="container">
            <h2 className="title">¿Por qué Virtual Ed Global?</h2>
            <SideTabs lang="es" />
          </div>
        </section>
        {/* Servicios */}
        <section>
          <div className="title-container">
            <div className="container">
              <div className="d-flex align-items-start tsb-wrapper">
                <h2 className="title-border">Servicios</h2>
                <p className="subtitle-border">{servicesDescription}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {data.allContentfulService.nodes.map((item) => {
                return (
                  <div className="col-12 col-md-6 cardbox-container" key={item.title_es}>
                    <CardBox title={item.title_es} link={"/servicios#" + slugify(item.title_es)} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Productos */}
        <section>
          <div className="title-container">
            <div className="container">
              <div className="d-flex align-items-start tsb-wrapper">
                <h2 className="title-border">Productos</h2>
                <p className="subtitle-border">{productsDescription}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {data.allContentfulProduct.nodes.map((item) => {
                return (
                  <div className="col-12 col-md-4 cardbox-container" key={item.title_es}>
                    <CardBox title={item.title_es} link={"/productos#" + slugify(item.title_es)} color="secondary" />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  Soluciones  */}
        <section>
          <div className="title-container">
            <div className="container">
              <div className="d-flex align-items-start tsb-wrapper">
                <h2 className="title-border">Soluciones</h2>
                <p className="subtitle-border">{solutionsDescription}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {data.allContentfulSolution.nodes.map((item) => {
                return (
                  <div className="col-12 col-md-6 cardbox-container" key={item.title_es}>
                    <CardBox title={item.title_es} link={"/soluciones#" + slugify(item.title_es)} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Partners */}
        <section>
          <LogosGallery title="En equipo con nuestros aliados tecnológicos, adaptamos las mejores soluciones para tus desafíos" />
        </section>

        {/* Contact */}
        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>Aquí estamos para conversar sobre Educación Superior.</strong> Esperamos tus consultas o
                comentarios.
              </p>
            }
            button_label="Conversemos"
          />
        </section>
      </div>
      
      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default IndexPage;
